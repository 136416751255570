























































import { ValidationProvider } from "vee-validate";
import { Component, Vue, Prop, Mixins, Emit } from "vue-property-decorator";

@Component({
    components: {
        ValidationProvider,
    },
})
export default class FormCheckbox extends Vue {
    @Prop({ default: true }) onValue!: any;

    @Prop() readonly value!: boolean;

    @Prop() name?: string;

    @Prop() labelText?: string;

    @Prop() rules?: string | object;

    localValue = false;

    @Emit("input")
    onChange() {
        return this.localValue;
    }

    get hasLabelSlot() {
        return !!this.$slots.label;
    }

    get checked() {
        return this.value;
    }

    set checked(val) {
        this.localValue = val;
    }
}
