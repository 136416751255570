import Vue from "vue";
import Vuex, { StoreOptions } from "vuex";
import modules from "@/store/modules";
import axios from "axios";

Vue.use(Vuex);

const api = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
});

const debug = process.env.NODE_ENV !== "production";
const store: StoreOptions<RootState> = {
    state: {
        version: process.env.VUE_APP_VERSION,
        api,
        page_loading: false,
    },
    modules,
    strict: debug,
    getters: {
        pageLoading(state: RootState): boolean {
            return state.page_loading;
        },
    },
    mutations: {
        PAGE_LOADING(state: RootState) {
            state.page_loading = true;
        },
        PAGE_LOADED(state: RootState) {
            state.page_loading = false;
        },
    },
    actions: {
        reset({ commit }: any) {
            const promises: any = [];
            Object.keys(modules).forEach((moduleName: string) => {
                commit(`${moduleName}/RESET`);
                promises.push(Promise.resolve());
            });

            return Promise.all(promises).then(() => Promise.resolve());
        },
        startLoadingPage({ commit }: any) {
            commit(`PAGE_LOADING`);
        },
        stopLoadingPage({ commit }: any) {
            commit(`PAGE_LOADED`);
        },
    },
};

api.interceptors.response.use(
    (res) => res.data,
    (err) => {
        const errorResponse: ErrorResponse = {
            status: err.response && err.response.status ? err.response.status : 500,
            errors: [],
        };

        if (!Object.prototype.hasOwnProperty.call(err.response.data, "errors")) {
            const error: ServerError = {
                message: "server_error",
            };
            errorResponse.errors.push(error);

            throw errorResponse;
        }

        errorResponse.errors = err.response.data.errors;

        throw errorResponse;
    },
);

const rootStore = new Vuex.Store<RootState>(store);

export default rootStore;
