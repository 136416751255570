const requireModule: any = require.context(".", false, /\.store\.ts$/);
const modules: any = {};

requireModule.keys().forEach((filename: string) => {
    const moduleName: any = filename.replace(/(\.\/|\.store\.ts)/g, "").replace(/^\w/, (c: string) => c.toLowerCase());

    modules[moduleName] = requireModule(filename).default || requireModule(filename);
});

export default modules;
