import { ActionTree, MutationTree, GetterTree } from "vuex";

const initialState: RecipesState = {
    all: undefined,
    total: undefined,
    viewing: undefined,
    categories: undefined,
    books: [],
};

const getters: GetterTree<RecipesState, RootState> = {
    all(state: RecipesState): Array<Recipe> | undefined {
        return state.all;
    },
    total(state: RecipesState): number | undefined {
        return state.total;
    },
    viewing(state: RecipesState): Recipe | undefined {
        return state.viewing;
    },
    categories(state: RecipesState): Array<string> | undefined {
        return state.categories;
    },
    books(state: RecipesState): Array<Book> {
        return state.books;
    },
};

const mutations: MutationTree<RecipesState> = {
    RESET(state: RecipesState) {
        state.all = undefined;
        state.viewing = undefined;
    },
    SET_RECIPES(state: RecipesState, payload: Array<Recipe>) {
        state.all = payload;
    },
    SET_TOTAL(state: RecipesState, payload: number) {
        state.total = payload;
    },
    SET_VIEWING(state: RecipesState, payload: Recipe) {
        state.viewing = payload;
    },
    SET_CATEGORIES(state: RecipesState, payload: Array<string>) {
        state.categories = payload;
    },
    SET_BOOKS(state: RecipesState, payload: Array<Book>) {
        state.books = payload;
    },
    ADD_RECIPES(state: RecipesState, payload: Recipe[]) {
        if (state.all) {
            state.all = [...state.all, ...payload];
        } else {
            state.all = payload;
        }
    },
};

const actions: ActionTree<RecipesState, RootState> = {
    index({ commit, rootState }, payload: RecipesIndexPayload) {
        const params = new URLSearchParams();
        params.append("q[and][active][]", "true");

        if (payload && payload.per_page) {
            params.append("per_page", payload.per_page.toString());
        }

        if (payload && payload.categories) {
            params.append("q[and][categories][]", payload.categories);
        }

        if (payload && payload.query) {
            params.append("q[and][or][title][]", `like:*${encodeURIComponent(payload.query.toLowerCase())}*`);
            params.append("q[and][or][title][]", `match:${encodeURIComponent(payload.query.toLowerCase())}`);
        }

        if (payload && payload.after && payload.after !== null) {
            payload.after.forEach((a: string | number) => {
                params.append("after[]", a.toString());
            });
        }

        params.append("s[created_at]", "desc");
        params.append("s[id]", "asc");

        const paramsString = decodeURIComponent(params.toString());

        return rootState.api
            .get(`recipes?${paramsString}`, { withCredentials: true })
            .then((response: { data: Array<Recipe>; total: number }) => {
                if (payload && payload.after && payload.after !== null) {
                    commit("ADD_RECIPES", response.data);
                } else {
                    commit("SET_RECIPES", response.data);
                }
                commit("SET_TOTAL", response.total);
                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    get({ commit, rootState }, payload: RecipesGetPayload) {
        const params = new URLSearchParams();

        const paramsString = decodeURIComponent(params.toString());
        params.append("q[and][active][]", "true");

        return rootState.api
            .get(`recipes/${payload.slug}?${paramsString}`, { withCredentials: true })
            .then((response: { data: Recipe }) => {
                commit("SET_VIEWING", response.data);

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    indexCategories({ commit, rootState }, payload: RecipesIndexCategoriesPayload) {
        const params = new URLSearchParams();

        if (payload.per_page) {
            params.append("per_page", payload.per_page.toString());
        }

        params.append("s[created_at]", "asc");
        params.append("s[amount]", "desc");

        const paramsString = decodeURIComponent(params.toString());

        return rootState.api
            .get(`recipe-categories?${paramsString}`, { withCredentials: true })
            .then((response: { data: Array<string> }) => {
                commit("SET_CATEGORIES", response.data);

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    indexBooks({ commit, rootState }) {
        const params = new URLSearchParams();

        params.append("q[active]", "true");
        params.append("s[ordinal]", "asc");
        params.append("s[created_at]", "asc");
        params.append("per_page", "50");

        const paramsString = decodeURIComponent(params.toString());

        return rootState.api
            .get(`books?${paramsString}`, { withCredentials: true })
            .then((response: { data: Array<Book> }) => {
                commit("SET_BOOKS", response.data);

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
