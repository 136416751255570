




import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { Debounce } from "vue-debounce-decorator";
import { MediaLibraryAttachment } from "@spatie/media-library-pro-vue2-attachment";

@Component({
    components: {
        MediaLibraryAttachment,
    },
})
export default class FormMediaAttachment extends Vue {
    @Prop() name!: string;

    @Prop() rules!: any;

    @Prop({ default: false }) multiple!: boolean;

    @Prop() initialValue?: Media | Media[];

    private get domain(): string {
        return process.env.VUE_APP_API_URL;
    }

    @Debounce(500)
    private handleChangeEvent(event: any) {
        if (Object.keys(event).length === 0) {
            return this.handleDeleteEvent(event);
        }

        this.handleChange(
            Object.values(event).map((e: any) => {
                return { uuid: e.uuid, order: e.order };
            }),
        );
    }

    @Emit("after-upload")
    private afterUpload(event: any) {
        return event;
    }

    @Emit("is-ready-to-submit-change")
    private readyToSubmit(event: any) {
        return event;
    }

    @Emit("change")
    private handleChange(event: any) {
        return event;
    }

    @Emit("changed")
    private handleChanged(event: any) {
        return event;
    }

    @Emit("deleted")
    private handleDeleteEvent(event: any) {
        return event;
    }
}
