import { ActionTree, MutationTree, GetterTree } from "vuex";

const initialState: FeelingState = {
    editing: undefined,
};

const getters: GetterTree<FeelingState, RootState> = {
    editing(state: FeelingState): FeelingPayload | undefined {
        return state.editing;
    },
};

const mutations: MutationTree<FeelingState> = {
    RESET_EDITING(state: FeelingState) {
        state.editing = undefined;
    },
    RESET(state: FeelingState) {
        state.editing = undefined;
    },
    SET_EDITING(state: FeelingState, payload: FeelingPayload) {
        state.editing = payload;
    },
};

const actions: ActionTree<FeelingState, RootState> = {
    create({ rootState }, payload: FeelingPayload) {
        return rootState.api
            .post(`accounts/${payload.account_id}/feelings`, payload, { withCredentials: true })
            .then((response: { data: FeelingPayload }) => {
                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    createReason({ rootState }, payload: FeelingReasonPayload) {
        return rootState.api
            .post(`accounts/${payload.account_id}/feeling-reasons`, payload, { withCredentials: true })
            .then((response: { data: FeelingReason[] }) => {
                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    setEditing({ commit }, payload: FeelingPayload) {
        commit("SET_EDITING", payload);
    },
    resetEditing({ commit }) {
        commit("RESET_EDITING");
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
