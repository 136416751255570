















import { ValidationProvider } from "vee-validate";
import { Component, Vue, Prop, Emit, Watch, Ref } from "vue-property-decorator";

@Component({
    components: {
        ValidationProvider,
    },
})
export default class FormTypeSelect extends Vue {
    @Prop() name?: string;

    @Prop() value?: string;

    @Prop() options?: string[];

    @Prop() rules?: string | object;

    @Prop({ default: false }) disabled!: boolean;

    @Prop() label?: string;

    @Prop({ default: "forms" }) i18n?: string;

    @Ref() readonly provider!: InstanceType<typeof ValidationProvider>;

    localValue: string | null = null;

    private get formValdiated(): boolean {
        return this.provider.$_veeObserver?.flags?.validated;
    }

    private get validateAs(): string {
        if (this.label) {
            return this.label;
        }

        if (this.name) {
            return this.name;
        }

        return `ye/no field`;
    }

    @Emit("input")
    private handleInput(value: string) {
        this.localValue = value;
        return this.localValue;
    }

    @Watch("value", { immediate: true })
    private valueChanged(newValue: string | null) {
        this.localValue = newValue;
    }
}
