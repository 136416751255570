declare global {
    interface Window {
        ybug_settings: {
            id: string;
        };
    }
}

import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import VueLazyload from "vue-lazyload";
import TextareaAutosize from "vue-textarea-autosize";
import VueMoment from "vue-moment";
import PortalVue from "portal-vue";
import { i18n } from "@/localization";
import VueToast from "vue-toast-notification";
import VueScrollactive from "vue-scrollactive";
import vueCountryRegionSelect from "vue-country-region-select";
import Vue2TouchEvents from "vue2-touch-events";
import VueCookies from "vue-cookies";
// @ts-ignore
import VAnimateCss from "v-animate-css";
// @ts-ignore
import lineClamp from "vue-line-clamp";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import VTooltip from "v-tooltip";

import "vue-toast-notification/dist/theme-sugar.css";
import "@/registerServiceWorker";
import "@/assets/scss/app.scss";
import "@/assets/svg/index";
import("@spatie/media-library-pro-styles");

// Ybug code start (https://ybug.io)
window.ybug_settings = { id: "j72fjb1ysf724a600rwt" };
const ybug = document.createElement("script");
ybug.type = "text/javascript";
ybug.async = true;
ybug.src = "https://widget.ybug.io/button/" + window.ybug_settings.id + ".js";
const s = document.getElementsByTagName("script")[0];
if (s.parentNode) {
    s.parentNode.insertBefore(ybug, s);
}
// Ybug code end

if (process.env.NODE_ENV === "production") {
    Sentry.init({
        Vue,
        dsn: "https://92223522200249c89c1a4f2860c1d4c0@o257713.ingest.sentry.io/6039812",
        integrations: [
            new BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                tracingOrigins: ["localhost", "my-site-url.com", /^\//],
            }),
        ],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
        logErrors: true,
    });
}

Vue.config.productionTip = false;

Vue.use(vueCountryRegionSelect);
Vue.use(VueScrollactive);
Vue.use(VueToast, {
    position: window.outerWidth >= 1024 ? "bottom-right" : "top",
});
Vue.use(PortalVue);
Vue.use(VueLazyload);
// @ts-ignore
Vue.use(TextareaAutosize);
Vue.use(VueMoment);
Vue.use(Vue2TouchEvents);
Vue.use(VueCookies);
Vue.use(VAnimateCss);
Vue.use(lineClamp);
Vue.use(VTooltip);

// Create Vue event bus
export const bus = new Vue();

new Vue({
    router,
    i18n,
    store,
    render: (h) => h(App),
    data: () => ({
        breakpoints: ["xs"],
    }),
    mounted() {
        // @ts-ignore
        this.init();
        const self = this;
        // @ts-ignore
        window.addEventListener("resize", () => self.init());
        // @ts-ignore
        window.addEventListener("orientationchange", () => self.init());
    },
    methods: {
        init() {
            const vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty("--vh", `${vh}px`);
            if (window.outerWidth >= 576) {
                this.breakpoints = ["xs", "sm"];
            }
            if (window.outerWidth >= 768) {
                this.breakpoints = ["xs", "sm", "md"];
            }
            if (window.outerWidth >= 1024) {
                this.breakpoints = ["xs", "sm", "md", "lg"];
            }
            if (window.outerWidth >= 1280) {
                this.breakpoints = ["xs", "sm", "md", "lg", "xl"];
            }
        },
    },
    computed: {
        isMobile() {
            return this.breakpoints.includes("xs") && !this.breakpoints.includes("xl");
        },
        isDesktop() {
            return this.breakpoints.includes("xl");
        },
        isFullScreenApp() {
            // @ts-ignore
            return window.navigator.standalone;
        },
    },
}).$mount("#app");
