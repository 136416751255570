
















import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class FormError extends Vue {
    @Prop() errorResponse?: ErrorResponse;
}
