import { ActionTree, MutationTree, GetterTree } from "vuex";
import PanicData from "@/assets/json/panic-data.json";
import store from "@/store/index";

const initialState: PanicState = {
    panicItem: undefined,
    showOverlay: false,
};

const getters: GetterTree<PanicState, RootState> = {
    panicItem(state: PanicState): PanicItem | undefined {
        return state.panicItem;
    },
    showOverlay(state: PanicState): boolean {
        return state.showOverlay;
    },
};

const mutations: MutationTree<PanicState> = {
    RESET(state: PanicState) {
        state.panicItem = undefined;
        state.showOverlay = false;
    },
    SET_PANIC_ITEM(state: PanicState, payload: PanicItem) {
        state.panicItem = payload;
    },
    SET_PANIC_OVERLAY(state: PanicState, payload: boolean) {
        state.showOverlay = payload;
    },
};

const actions: ActionTree<PanicState, RootState> = {
    setRandomPanicItem({ commit }) {
        const randomPanicItem: PanicItem = PanicData.panic_items[Math.floor(Math.random() * PanicData.panic_items.length)] as PanicItem;
        commit("SET_PANIC_ITEM", randomPanicItem);
    },
    setOverlay({ commit, state }, payload: boolean) {
        if (store.getters["auth/isSubscriber"]) {
            commit("SET_PANIC_OVERLAY", payload);
            if (payload === false) {
                store.dispatch("panic/setRandomPanicItem");
            }
        } else if (payload === true) {
            store.dispatch("auth/setPaywall", true);
        }
    },
    reset({ commit }) {
        commit("RESET");
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
