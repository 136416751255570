import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import store from "@/store/index";
import { parse } from "fecha";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: "/",
        name: "home",
        beforeEnter: guardAuth,
        component: loadView("home"),
        meta: {
            group: ["guest", "auth"],
        },
    },

    /*
    | -----------------
    | Auth  PAGES
    | -----------------
    | 
    */
    {
        path: "/",
        component: loadView("guest/index"),
        children: [
            {
                path: "authenticatie",
                name: "authenticate",
                beforeEnter: guardAuth,
                component: loadView("guest/authenticate"),
                meta: {
                    title: "Welcome | So We Eat",
                    group: ["guest"],
                },
            },
            {
                path: "login",
                name: "login",
                beforeEnter: guardAuth,
                component: loadView("guest/login"),
                meta: {
                    title: "Inloggen | So We Eat",
                    group: ["guest"],
                },
            },
            {
                path: "registreren",
                name: "register",
                beforeEnter: guardAuth,
                component: loadView("guest/register"),
                meta: {
                    title: "Registreer | So We Eat",
                    group: ["guest"],
                },
            },
            {
                path: "uitnodiging/:token",
                name: "invite",
                beforeEnter: guardAuth,
                component: loadView("guest/invite"),
                meta: {
                    title: "Account aanmaken | So We Eat",
                    group: ["guest"],
                },
            },
            {
                path: "/wachtwoord-vergeten",
                name: "password-forgot",
                beforeEnter: guardAuth,
                component: loadView("guest/password-forgot"),
                meta: {
                    title: "Wachtwoord vergeten | So We Eat",
                    group: ["guest"],
                },
            },
            {
                path: "/wijzig-wachtwoord",
                name: "password-reset",
                beforeEnter: guardLogout,
                component: loadView("guest/password-reset"),
                meta: {
                    title: "Wijzig wachtwoord | So We Eat",
                    group: ["guest"],
                },
            },
            {
                path: "/confirm-email",
                alias: "/email-bevestigen",
                name: "confirm-email",
                beforeEnter: guardAuth,
                component: loadView("guest/confirm-email"),
                meta: {
                    title: "Email bevestigen | So We Eat",
                    group: ["guest"],
                },
            },
        ],
    },

    {
        path: "/",
        component: loadView("auth/index"),
        children: [
            {
                path: "/profiel",
                name: "profile",
                component: loadView("auth/user/account/show"),
                beforeEnter: guardAuth,
                meta: {
                    title: "Profiel | So We Eat",
                    group: ["auth"],
                    beforeResolve(to: any, from: any, next: any) {
                        store.dispatch("startLoadingPage");

                        store.dispatch("auth/me").then((auth: Account) => {
                            if ((store.getters["auth/isSubscriber"] && !store.getters["auth/isDietician"]) || store.getters["auth/isAdmin"]) {
                                const payload = {
                                    id: auth.id,
                                    period: "week",
                                };
                                store.dispatch("dashboard/getDashboard", payload).then(() => {
                                    store.dispatch("stopLoadingPage");
                                });
                            } else {
                                store.dispatch("stopLoadingPage");
                            }
                        });

                        next();
                    },
                },
            },
            {
                path: "/profiel/:slug",
                name: "profile-detail",
                component: loadView("auth/user/account/show"),
                beforeEnter: guardAuth,
                meta: {
                    title: "Profiel | So We Eat",
                    group: ["auth"],
                },
            },
            {
                path: "/betaling/:id",
                name: "payment-pending",
                component: loadView("auth/user/account/payment"),
                beforeEnter: guardAuth,
                meta: {
                    title: "Betaling | So We Eat",
                    group: ["auth"],
                },
            },
            {
                path: "/dagboek",
                name: "journal",
                component: loadView("auth/user/journal/show"),
                beforeEnter: guardAuth,
                meta: {
                    title: "Dagboek | So We Eat",
                    group: ["auth"],
                    beforeResolve(to: any, from: any, next: any) {
                        store.dispatch("startLoadingPage");
                        store.dispatch("temp-media/resetMedia");
                        store.dispatch("temp-media/reset");

                        if (to && to.query && to.query.datum && to.query.datum != "") {
                            const date = parse(to.query.datum, "YYYY-MM-DD");

                            if (date) {
                                const now = new Date();
                                const hours = now.getHours();
                                const minutes = now.getMinutes();

                                date.setHours(hours);
                                date.setMinutes(minutes);

                                store.dispatch("journal/setViewingDay", date);
                            }
                        } else if (from && !["camera", "create-journal-entry", "create-journal-plan", "create-journal-outburst", "create-journal-party", "edit-image"].includes(from.name)) {
                            store.dispatch("journal/setViewingDay", new Date()).then(() => {
                                store.dispatch("stopLoadingPage");
                            });
                        } else {
                            store.dispatch("stopLoadingPage");
                        }

                        next();
                    },
                },
            },
            {
                path: "/maaltijd-toevoegen",
                name: "create-journal-entry",
                beforeEnter: guardAuth,
                component: loadView("auth/user/journal/create"),
                meta: {
                    title: "Maaltijd toevoegen | So We Eat",
                    group: ["auth"],
                    beforeResolve(to: any, from: any, next: any) {
                        store.dispatch("startLoadingPage");

                        if (from && !["camera", "edit-image"].includes(from.name)) {
                            store.dispatch("journal/resetEditing");
                        }

                        next();

                        store.dispatch("stopLoadingPage");
                    },
                },
            },
            {
                path: "/eetmoment-inplannen",
                name: "create-journal-plan",
                beforeEnter: guardAuth,
                component: loadView("auth/user/journal/plan"),
                meta: {
                    title: "Eetmoment inplannen | So We Eat",
                    group: ["auth"],
                },
            },
            {
                path: "/party-moment-inplannen",
                name: "create-journal-party",
                beforeEnter: guardAuth,
                component: loadView("auth/user/journal/party"),
                meta: {
                    title: "Party-moment inplannen | So We Eat",
                    group: ["auth"],
                },
            },
            {
                path: "/om-zeep",
                name: "create-journal-outburst",
                beforeEnter: guardAuth,
                component: loadView("auth/user/journal/outburst"),
                meta: {
                    title: "Om zeep | So We Eat",
                    group: ["auth"],
                },
            },
            {
                path: "/afbeelding-bewerken",
                name: "edit-image",
                beforeEnter: guardAuth,
                component: loadView("auth/user/journal/create-steps/image-edit"),
                meta: {
                    title: "Afbeelding bewerken | So We Eat",
                    group: ["auth"],
                },
            },
            {
                path: "/foto-maken",
                name: "camera",
                beforeEnter: guardAuth,
                component: loadView("auth/user/journal/create-steps/camera"),
                meta: {
                    title: "Foto maken | So We Eat",
                    group: ["auth"],
                },
            },
            {
                path: "/ontdek",
                name: "discover",
                component: loadView("auth/user/discover/show"),
                beforeEnter: guardAuth,
                meta: {
                    title: "Ontdek | So We Eat",
                    group: ["auth"],
                    beforeResolve(to: any, from: any, next: any) {
                        store.dispatch("startLoadingPage");

                        store.dispatch("auth/me").then((auth: Account) => {
                            const recipesPayload: RecipesIndexPayload = {
                                per_page: 5,
                            };

                            const categoriesPayload: RecipesIndexPayload = {
                                per_page: 6,
                            };

                            const reactionsPayload: JournalIndexReactionsPayload = {
                                account_id: auth.id,
                            };

                            const promises = [store.dispatch("recipes/indexBooks"), store.dispatch("journal/indexReactions", reactionsPayload)];

                            if (store.getters["auth/isSubscriber"]) {
                                promises.push(store.dispatch("recipes/index", recipesPayload));
                                promises.push(store.dispatch("recipes/indexCategories", categoriesPayload));
                                promises.push(store.dispatch("panic/setRandomPanicItem"));
                            } else {
                                promises.push(store.dispatch("auth/setPaywall", true));
                            }

                            Promise.all(promises).then(
                                () => {
                                    store.dispatch("stopLoadingPage");
                                },
                                (err: ErrorResponse) => {
                                    handleUnauthorized(err, next);
                                },
                            );
                        });

                        next();
                    },
                },
            },
            {
                path: "/recepten/categorieen",
                name: "recipe-categories",
                component: loadView("auth/user/recipes/categories"),
                beforeEnter: guardAuth,
                meta: {
                    title: "Receptcategorieën | So We Eat",
                    group: ["auth"],
                    beforeResolve(to: any, from: any, next: any) {
                        store.dispatch("startLoadingPage");

                        const categoriesPayload: RecipesIndexPayload = {
                            per_page: 100,
                        };

                        store.dispatch("recipes/indexCategories", categoriesPayload).then(() => {
                            store.dispatch("stopLoadingPage");
                        });

                        next();
                    },
                },
            },
            {
                path: "/recepten/:category",
                name: "recipes",
                component: loadView("auth/user/recipes/recipes"),
                beforeEnter: guardAuth,
                meta: {
                    title: "Recepten | So We Eat",
                    group: ["auth"],
                    beforeResolve(to: any, from: any, next: any) {
                        store.dispatch("startLoadingPage");

                        const recipesPayload: RecipesIndexPayload = {
                            categories: to.params.category,
                            per_page: 12,
                        };

                        store.dispatch("recipes/index", recipesPayload).then(() => {
                            store.dispatch("stopLoadingPage");
                        });

                        next();
                    },
                },
            },
            {
                path: "/recept/:slug",
                name: "recipe",
                component: loadView("auth/user/recipes/recipe"),
                beforeEnter: guardAuth,
                meta: {
                    title: "Recept | So We Eat",
                    group: ["auth"],
                    beforeResolve(to: any, from: any, next: any) {
                        store.dispatch("startLoadingPage");

                        const payload: RecipesGetPayload = {
                            slug: to.params.slug,
                        };

                        store.dispatch("recipes/get", payload).then(() => {
                            store.dispatch("stopLoadingPage");
                        });

                        next();
                    },
                },
            },
            {
                path: "/gebruikers",
                name: "admin-users",
                component: loadView("auth/admin/users/index"),
                beforeEnter: guardAuth,
                meta: {
                    title: "Gebruikers | So We Eat",
                    group: ["dietician"],
                    beforeResolve(to: any, from: any, next: any) {
                        store.dispatch("startLoadingPage");

                        store.dispatch("account/index");

                        next();

                        store.dispatch("stopLoadingPage");
                    },
                },
            },
            {
                path: "/gebruikers/:id",
                name: "admin-user",
                component: loadView("auth/admin/users/show"),
                beforeEnter: guardAuth,
                meta: {
                    title: "Gebruiker | So We Eat",
                    group: ["dietician"],
                    beforeResolve(to: any, from: any, next: any) {
                        store.dispatch("startLoadingPage");

                        const payload = {
                            id: to.params.id,
                            period: "week",
                        };
                        store.dispatch("dashboard/getDashboard", payload).then(() => {
                            store.dispatch("stopLoadingPage");
                        });

                        next();
                    },
                },
            },
            {
                path: "/dietist-uitnodigen",
                name: "admin-coach-create",
                component: loadView("auth/admin/coaches/create"),
                beforeEnter: guardAuth,
                meta: {
                    title: "Diëtist of deskundige uitnodigen | So We Eat",
                    group: ["admin"],
                },
            },
        ],
    },

    {
        path: "/",
        component: loadView("legal/index"),
        children: [
            {
                path: "/privacybeleid",
                name: "privacy-policy",
                component: loadView("legal/privacy-policy"),
                meta: {
                    title: "Privacybeleid | So We Eat",
                    group: ["guest", "auth"],
                    beforeResolve(to: any, from: any, next: any) {
                        store.dispatch("startLoadingPage");

                        next();

                        store.dispatch("stopLoadingPage");
                    },
                },
            },

            {
                path: "/algemene-voorwaarden",
                name: "disclaimer",
                component: loadView("legal/disclaimer"),
                meta: {
                    title: "Algemene voorwaarden | So We Eat",
                    group: ["guest", "auth"],
                    beforeResolve(to: any, from: any, next: any) {
                        store.dispatch("startLoadingPage");

                        next();

                        store.dispatch("stopLoadingPage");
                    },
                },
            },
        ],
    },

    {
        path: "/logout",
        name: "logout",
        beforeEnter: guardLogout,
        component: loadView("home"),
    },

    {
        path: "/errors/:code",
        name: "error",
        component: loadView("errors/general"),
        meta: {
            title: "Errors | So We Eat",
            group: ["auth"],
            beforeResolve(to: any, from: any, next: any) {
                store.dispatch("startLoadingPage");

                next();

                store.dispatch("stopLoadingPage");
            },
        },
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    },
});

router.beforeResolve(async (routeTo: any, routeFrom: any, next: any) => {
    try {
        if (routeFrom.meta && routeFrom.meta.leaveCallback) {
            await routeFrom.meta.leaveCallback(routeTo, routeFrom);
        }

        for (const route of routeTo.matched) {
            await new Promise((resolve, reject) => {
                if (route.meta && route.meta.title) {
                    document.title = route.meta.title;
                }
                if (route.meta && route.meta.group) {
                    document.querySelector("body")?.classList.add(route.meta.group);
                }

                if (route.meta && route.meta.beforeResolve) {
                    route.meta.beforeResolve(routeTo, routeFrom, (...args: any) => {
                        if (args.length) {
                            next(...args);
                            reject(new Error("Redirected"));
                        } else {
                            resolve({ success: true });
                        }
                    });
                } else {
                    resolve({ success: true });
                }
            });
        }
    } catch (error) {
        return;
    }
    next();
});

/**
 * Guard guest middleware
 * Redirect to the login page when the user is not logged in
 *
 * @param {to} string The route the user was comming from
 * @param {from} string The route the user is going to
 * @param {next} function The next route funciton
 */
async function guardAuth(to: any, from: any, next: any): Promise<void> {
    if (!to.meta.group) {
        console.error("No group assigned to route: ", to);
        return;
    }

    if (!Array.isArray(to.meta.group)) {
        console.error("To meta group is not an Array", to);
        return;
    }

    try {
        const auth: Account = await store.dispatch("auth/me");
        const role = (await auth.permissions.includes("account.index")) ? "admin" : (await auth.permissions.includes("account.index.coachee")) ? "dietician" : "user";

        store.dispatch("auth/setPaywall", false);
        store.dispatch("panic/setOverlay", false);

        if (to.meta.group.includes("guest") && auth && auth.id) {
            if (role === "user") {
                return next({ name: "discover" });
            }

            if (role === "admin" || role === "dietician") {
                return next({ name: "admin-users" });
            }

            return next({ name: "error", params: { code: "401" } });
        }

        if (to.meta.group.includes("auth") && (!auth || !auth.id)) {
            return next({ name: "authenticate" });
        }

        if (to.meta.group.includes("admin") && (!auth || !auth.id || role !== "admin")) {
            return next({ name: "discover" });
        }

        if (to.meta.group.includes("dietician") && (!auth || !auth.id || (role !== "dietician" && role !== "admin"))) {
            return next({ name: "discover" });
        }
    } catch (e) {
        console.error(e);
        if (to.meta.group.includes("guest") && !to.meta.group.includes("auth")) {
            return next();
        }
        return next({ name: "authenticate" });
    }

    return next();
}

/**
 * Guard logout middleware
 * Redirect to the login page when the user is not logged in
 *
 * @param {to} string The route the user was comming from
 * @param {from} string The route the user is going to
 * @param {next} function The next route funciton
 */
async function guardLogout(to: any, from: any, next: any): Promise<void> {
    await store.dispatch("auth/logout");

    if (to.name === "password-reset") {
        return next();
    }

    return next({ name: "authenticate" });
}

function loadView(view: string) {
    return () => import(`@/views/${view}.vue`);
}

function handleUnauthorized(error: ErrorResponse, next: any) {
    if (error.status === 401) {
        next({ name: "error", params: { code: "401" } });
    }
}

router.onError((error) => {
    if (/Loading (CSS )?chunk [a-zA-Z]+-([0-9]+([a-zA-Z]+[0-9]+)+) failed/i.test(error.message)) {
        console.log("reloading page now.");
        window.location.reload();
    }
});

export default router;
