import { ActionTree, MutationTree, GetterTree } from "vuex";
import rootStore from "..";

const initialState: AuthState = {
    auth: undefined,
    showPaywall: false,
};

const getters: GetterTree<AuthState, RootState> = {
    me(state: AuthState): Account | undefined {
        return state.auth;
    },
    isAdmin(state: AuthState): boolean | undefined {
        return state.auth && state.auth.permissions.includes("account.index");
    },
    isDietician(state: AuthState): boolean | undefined {
        return state.auth && state.auth.permissions.includes("account.index.coachee");
    },
    isSubscriber(state: AuthState): boolean | undefined {
        return state.auth && state.auth.permissions.includes("recipe.index");
    },
    showPaywall(state: AuthState, getters: GetterTree<AuthState, RootState>): boolean {
        return state.showPaywall && !getters.isSubscriber;
    },
};

const mutations: MutationTree<AuthState> = {
    RESET(state: AuthState) {
        state.auth = undefined;
        // state.steps = steps;
        localStorage.removeItem(`${process.env.VUE_APP_NAME}_SKIP_STEP`);
    },
    SET_AUTH(state: AuthState, payload: Account) {
        state.auth = payload;
    },
    SET_PAYWALL(state: AuthState, payload: boolean) {
        state.showPaywall = payload;
    },
};

const actions: ActionTree<AuthState, RootState> = {
    checkEmail({ rootState }, payload: CheckEmailPayload) {
        return rootState.api
            .post("email/validate", payload, { withCredentials: true })
            .then(() => {
                return Promise.resolve();
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },

    login({ rootState }, payload: LoginPayload) {
        return rootState.api
            .post("login", payload, { withCredentials: true })
            .then(() => {
                return Promise.resolve();
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },

    register({ rootState }, payload: RegisterPayload) {
        return rootState.api
            .post("register", payload, { withCredentials: true })
            .then(() => {
                return Promise.resolve();
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },

    passwordForgot({ rootState }, payload: PasswordForgotPayload) {
        return rootState.api
            .post("password/forgot", payload, { withCredentials: true })
            .then(() => {
                return Promise.resolve();
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },

    passwordReset({ rootState }, payload: PasswordResetPayload) {
        return rootState.api
            .post("password/reset", payload, { withCredentials: true })
            .then(() => {
                return Promise.resolve();
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },

    passwordCheck({ rootState }, payload: PasswordCheckPayload) {
        return rootState.api
            .post("password/check", payload, { withCredentials: true })
            .then(() => {
                return Promise.resolve();
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },

    confirmEmail({ rootState }, payload: ConfirmEmailPayload) {
        return rootState.api
            .post("email/confirm", payload, { withCredentials: true })
            .then(() => {
                return Promise.resolve();
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },

    changeEmail({ rootState }, payload: ChangeEmailPayload) {
        return rootState.api
            .post(`accounts/${payload.account_id}/email-changes/requests`, { email: payload.email }, { withCredentials: true })
            .then(() => {
                return Promise.resolve();
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },

    cancelChangeEmail({ rootState }, payload: CancelChangeEmailPayload) {
        return rootState.api
            .post(`accounts/${payload.account_id}/email-changes/cancel`, null, { withCredentials: true })
            .then(() => {
                return Promise.resolve();
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },

    me({ commit, rootState, state }, dontUseStorage: boolean | undefined) {
        if (state.auth && !dontUseStorage) {
            return new Promise((resolve) => resolve(state.auth));
        }

        return rootState.api
            .get("me", { withCredentials: true })
            .then((response: { data: Account }) => {
                commit("SET_AUTH", response.data);

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },

    setAuth({ commit }, payload: Account) {
        commit("SET_AUTH", payload);
    },

    setPaywall({ commit }, payload: boolean) {
        commit("SET_PAYWALL", payload);
    },

    subscribe({ rootState, state }) {
        if (state.auth) {
            return rootState.api
                .post(`/accounts/${state.auth.id}/payments`, null, { withCredentials: true })
                .then((response: { data: any; redirect_url: string }) => {
                    if (response.redirect_url) {
                        window.location.href = response.redirect_url;
                    }
                    return Promise.resolve(response.data);
                })
                .catch((e: ErrorResponse) => {
                    return Promise.reject(e);
                });
        }
        return Promise.reject(new Error("Not logged in"));
    },

    getPaymentStatus({ rootState, state }, payment_id: number | string) {
        if (state.auth) {
            return rootState.api
                .get(`/accounts/${state.auth.id}/payments/${payment_id}`, { withCredentials: true })
                .then((response: { data: { status: string } }) => {
                    return Promise.resolve(response.data.status);
                })
                .catch((e: ErrorResponse) => {
                    return Promise.reject(e);
                });
        }
        return Promise.reject(new Error("Not logged in"));
    },

    logout({ commit, rootState, dispatch }) {
        return rootState.api
            .post("logout", {}, { withCredentials: true })
            .then(() => {
                dispatch("reset", null, { root: true });

                commit("RESET");

                return Promise.resolve();
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
