import { ActionTree, MutationTree, GetterTree } from "vuex";
import Vue from "vue";

const initialState: AccountState = {
    all: [],
    total: undefined,
    viewing: undefined,
};

const getters: GetterTree<AccountState, RootState> = {
    all(state: AccountState): Account[] {
        return state.all;
    },
    total(state: AccountState): number | undefined {
        return state.total;
    },
    viewing(state: AccountState): Account | undefined {
        return state.viewing;
    },
};

const mutations: MutationTree<AccountState> = {
    RESET(state: AccountState) {
        state.all = [];
        state.viewing = undefined;
    },
    UPDATE_MODEL(state: AccountState, payload: Account) {
        const index = state.all.findIndex((model: Account) => model.id === payload.id);

        if (index === -1) {
            state.all.push(payload);
        } else {
            Vue.set(state.all, index, payload);
        }

        if (state.viewing && state.viewing.id === payload.id) {
            state.viewing = payload;
        }
    },
    ADD_MODEL(state: AccountState, payload: Account) {
        state.all.push(payload);
    },
    SET_MODELS(state: AccountState, payload: Account[]) {
        state.all = payload;
    },
    SET_TOTAL(state: AccountState, payload: number) {
        state.total = payload;
    },
    ADD_MODELS(state: AccountState, payload: Account[]) {
        state.all = [...state.all, ...payload];
    },
    SET_VIEWING(state: AccountState, payload: Account) {
        state.viewing = payload;
    },
};

const actions: ActionTree<AccountState, RootState> = {
    create({ commit, rootState }, payload: AccountPayload) {
        return rootState.api
            .post(`accounts`, payload, { withCredentials: true })
            .then((response: { data: Account }) => {
                commit("ADD_MODEL", response.data);

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    update({ commit, rootState }, { payload, id }: { payload: AccountPayload; id: number }) {
        return rootState.api
            .put(`accounts/${id}`, payload, { withCredentials: true })
            .then((response: { data: Account }) => {
                commit("UPDATE_MODEL", response.data);

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    index({ commit, rootState }, payload?: { query?: string; after?: Array<string> }) {
        const params = new URLSearchParams();
        if (payload && payload.query) {
            params.append("q[and][or][first_name][]", `like:*${encodeURIComponent(payload.query)}*`);
            params.append("q[and][or][first_name][]", `match:${encodeURIComponent(payload.query)}`);
            params.append("q[and][or][last_name][]", `like:*${encodeURIComponent(payload.query)}*`);
            params.append("q[and][or][last_name][]", `match:${encodeURIComponent(payload.query)}`);
            params.append("q[and][or][email][]", `like:*${encodeURIComponent(payload.query)}*`);
            params.append("q[and][or][email][]", `match:${encodeURIComponent(payload.query)}`);
        }
        if (payload && payload.after && payload.after !== null) {
            payload.after.forEach((a: string) => {
                params.append("&after[]", a);
            });
        }
        params.append("s[id]", "asc");
        params.append("per_page", "15");

        const paramsString = decodeURIComponent(params.toString());
        return rootState.api
            .get(`accounts?${paramsString}`, { withCredentials: true })
            .then((response: { data: Account[]; total: number }) => {
                if (payload && payload.after && payload.after !== null) {
                    commit("ADD_MODELS", response.data);
                } else {
                    commit("SET_MODELS", response.data);
                }
                commit("SET_TOTAL", response.total);

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    read({ commit, rootState }, payload: { id: number }) {
        return rootState.api
            .get(`accounts/${payload.id}`, { withCredentials: true })
            .then((response: { data: Account }) => {
                commit("UPDATE_MODEL", response.data);

                commit("SET_VIEWING", response.data);

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    getCoach({ rootState }, email: string) {
        return rootState.api
            .get(`coaches/${encodeURIComponent(email)}`, { withCredentials: true })
            .then((response: { data: { id: number } }) => {
                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    assignCoach({ rootState }, payload: { coachable_id: number; account_id: number }) {
        return rootState.api
            .post(`accounts/${payload.account_id}/relations`, { coachable_id: payload.coachable_id }, { withCredentials: true })
            .then((response: { data: { id: number } }) => {
                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    invite({ rootState }, payload: AccountInvitePayload) {
        return rootState.api
            .post(`invites`, payload, { withCredentials: true })
            .then((response: { data: Account }) => {
                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    delete({ rootState }, payload: { id: number }) {
        return rootState.api
            .delete(`accounts/${payload.id}`, { withCredentials: true })
            .then(() => {
                return Promise.resolve();
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    setViewing({ commit }, payload: Account) {
        commit("UPDATE_MODEL", payload);
        commit("SET_VIEWING", payload);
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
