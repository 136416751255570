













































import { ValidationProvider } from "vee-validate";
import { Component, Vue, Prop, Emit, Watch, Ref } from "vue-property-decorator";

@Component({
    components: {
        ValidationProvider,
    },
})
export default class FormTextarea extends Vue {
    @Prop() name?: string;

    @Prop() value?: string;

    @Prop() placeholder?: string;

    @Prop() rules?: string | object;

    @Prop() label?: string;

    @Prop({ default: " text-12" }) labelClass!: string;

    @Prop({ default: false }) disabled!: boolean;

    @Prop({ default: "text" }) type!: string;

    @Prop({ default: false }) autoFocus!: boolean;

    @Ref() input!: HTMLInputElement;

    @Ref() readonly provider!: InstanceType<typeof ValidationProvider>;

    isFocussed = false;

    localValue = "";

    validAtleastOnce = false;

    private mounted() {
        if (this.autoFocus) {
            this.input.focus();
        }
    }

    private get formValdiated(): boolean {
        return this.provider.$_veeObserver?.flags?.validated;
    }

    private get validateAs(): string {
        if (this.label) {
            return this.label;
        }

        if (this.placeholder) {
            return this.placeholder;
        }

        if (this.name) {
            return this.name;
        }

        return `${this.type} field`;
    }

    private get hasPrefix(): boolean {
        return !!this.$slots.prefix;
    }

    private get hasSuffix(): boolean {
        return !!this.$slots.suffix;
    }

    private eagerIfDirty(_a: { flags: any; errors: any }) {
        var errors = _a.errors;
        var flags = _a.flags;
        if (errors.length) {
            return {
                on: ["input", "change"],
            };
        }

        if (flags.valid && !this.validAtleastOnce) {
            this.validAtleastOnce = true;
        }

        if (flags.dirty) {
            return {
                on: ["change", "blur", "input"],
            };
        }

        return {
            on: ["change"],
        };
    }

    @Emit("input")
    private handleInput() {
        return this.localValue;
    }

    @Emit("blur")
    private handleBlur(e: Event): Event {
        if (this.provider.flags.dirty) {
            this.validAtleastOnce = true;
        }
        this.isFocussed = false;
        return e;
    }
    @Emit("focus")
    private handleFocus(e: Event): Event {
        this.isFocussed = true;
        return e;
    }

    @Watch("value", { immediate: true })
    private valueChanged(newValue: string) {
        this.localValue = newValue;
    }
}
