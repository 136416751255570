



















import { ValidationProvider } from "vee-validate";
import { Component, Vue, Prop, Emit, Watch, Ref } from "vue-property-decorator";
import { Getter, Action } from "vuex-class";
// @ts-ignore
import { v4 as uuidv4 } from "uuid";

@Component({
    components: {
        ValidationProvider,
    },
})
export default class FormInput extends Vue {
    @Action("temp-media/upload") uploadTempImage!: TempMediaUpload;

    @Prop() name?: string;

    @Prop() value?: string;

    @Prop() rules?: string | object;

    @Prop() label?: string;

    @Prop() labelClass!: string;

    @Prop({ default: true }) showError?: boolean;

    @Prop({ default: false }) disabled!: boolean;

    @Ref() input!: HTMLInputElement;

    @Ref() readonly provider!: InstanceType<typeof ValidationProvider>;

    isFocussed = false;

    localValue = "";

    validAtleastOnce = false;

    private get formValdiated(): boolean {
        return this.provider.$_veeObserver?.flags?.validated;
    }

    private get validateAs(): string {
        if (this.label) {
            return this.label;
        }

        if (this.name) {
            return this.name;
        }

        return "image field";
    }

    private eagerIfDirty(_a: { flags: any; errors: any }) {
        var errors = _a.errors;
        var flags = _a.flags;
        if (errors.length) {
            return {
                on: ["input", "change"],
            };
        }

        if (flags.valid && !this.validAtleastOnce) {
            this.validAtleastOnce = true;
        }

        if (flags.dirty) {
            return {
                on: ["change", "blur", "input"],
            };
        }

        return {
            on: ["change"],
        };
    }

    @Emit("change")
    private handleFileChange(e: any) {
        var files = e.target.files || e.dataTransfer.files;
        if (!files.length) return;
        const payload: TempMediaUploadPayload = {
            name: files[0].name,
            uuid: uuidv4(),
            file: files[0],
        };
        this.uploadTempImage(payload);
    }

    @Emit("blur")
    private handleBlur(e: Event): Event {
        if (this.provider && this.provider.flags.dirty) {
            this.validAtleastOnce = true;
        }
        this.isFocussed = false;
        return e;
    }

    @Emit("focus")
    private handleFocus(e: Event): Event {
        this.isFocussed = true;
        return e;
    }

    @Watch("value", { immediate: true })
    private valueChanged(newValue: string) {
        this.localValue = newValue;
    }
}
