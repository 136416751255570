import { ActionTree, MutationTree, GetterTree } from "vuex";
import Vue from "vue";

const initialState: PaymentMethodState = {
    all: [],
    viewing: undefined,
};

const getters: GetterTree<PaymentMethodState, RootState> = {
    all(state: PaymentMethodState): PaymentMethod[] {
        return state.all;
    },
    viewing(state: PaymentMethodState): PaymentMethod | undefined {
        return state.viewing;
    },
    primaryPaymentMethod(state: PaymentMethodState): string | null {
        return localStorage.getItem(`${process.env.VUE_APP_NAME}_PRIMARY_PAYMENT_METHOD`);
    },
};

const mutations: MutationTree<PaymentMethodState> = {
    RESET(state: PaymentMethodState) {
        state.all = [];
        state.viewing = undefined;
    },
    UPDATE_MODEL(state: PaymentMethodState, payload: PaymentMethod) {
        const index = state.all.findIndex((model: PaymentMethod) => model.id === payload.id);

        if (index === -1) {
            state.all.push(payload);
        } else {
            Vue.set(state.all, index, payload);
        }
    },
    ADD_MODEL(state: PaymentMethodState, payload: PaymentMethod) {
        state.all.push(payload);
    },
    SET_MODELS(state: PaymentMethodState, payload: PaymentMethod[]) {
        state.all = payload;
    },
    SET_VIEWING(state: PaymentMethodState, payload: PaymentMethod) {
        state.viewing = payload;
    },
};

const actions: ActionTree<PaymentMethodState, RootState> = {
    create({ commit, rootState }, payload: CreatePaymentMethodPayload) {
        return rootState.api
            .post(`accounts/${payload.accountId}/payment-methods`, payload, { withCredentials: true })
            .then((response: { data: PaymentMethod }) => {
                commit("ADD_MODEL", response.data);

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    index({ commit, rootState }, payload: IndexPaymentMethodPayload) {
        return rootState.api
            .get(`accounts/${payload.accountId}/payment-methods`, { withCredentials: true })
            .then((response: { data: PaymentMethod }) => {
                commit("SET_MODELS", response.data);

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    read({ commit, rootState }, payload: ReadPaymentMethodPayload) {
        return rootState.api
            .get(`accounts/${payload.accountId}/payment-methods/${payload.paymentMethodId}`, { withCredentials: true })
            .then((response: { data: PaymentMethod }) => {
                commit("UPDATE_MODEL", response.data);

                commit("SET_VIEWING", response.data);

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    delete({ rootState }, payload: DeletePaymentMethodPayload) {
        return rootState.api
            .delete(`accounts/${payload.accountId}/payment-methods/${payload.paymentMethodId}`, { withCredentials: true })
            .then(() => {
                return Promise.resolve();
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    setPrimary({ rootState }, payload: PrimaryPaymentMethodPayload) {
        localStorage.setItem(`${process.env.VUE_APP_NAME}_PRIMARY_PAYMENT_METHOD`, `${payload.paymentMethodId}`);
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
