import { ActionTree, MutationTree, GetterTree } from "vuex";

const initialState: TempMediaState = {
    media: undefined,
    localFiles: undefined,
};

const getters: GetterTree<TempMediaState, RootState> = {
    media(state: TempMediaState): Media | undefined {
        return state.media;
    },
    localFile(state: TempMediaState): TempMediaUploadPayload | undefined {
        return state.localFiles;
    },
};

const mutations: MutationTree<TempMediaState> = {
    RESET(state: TempMediaState) {
        state.localFiles = undefined;
    },
    RESET_MEDIA(state: TempMediaState) {
        state.media = undefined;
    },
    SET_MEDIA(state: TempMediaState, payload: Media) {
        state.media = payload;
    },
    SET_LOCALFILE(state: TempMediaState, payload: TempMediaUploadPayload) {
        state.localFiles = payload;
    },
};

const actions: ActionTree<TempMediaState, RootState> = {
    upload({ rootState, commit }, payload: TempMediaUploadPayload) {
        commit("SET_LOCALFILE", payload);
        const formData = new FormData();
        formData.append("name", payload.name);
        formData.append("file", payload.file);
        formData.append("uuid", payload.uuid);
        return rootState.api
            .post("media-library-pro/uploads", formData, { withCredentials: true })
            .then((response: Media) => {
                commit("SET_MEDIA", response);
                commit("SET_LOCALFILE", undefined);

                return Promise.resolve(response);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    reset({ commit }) {
        commit("RESET");
    },
    resetMedia({ commit }) {
        commit("RESET_MEDIA");
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
