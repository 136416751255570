import { ActionTree, MutationTree, GetterTree } from "vuex";
import { format } from "fecha";
const cloneDeep = require("lodash/cloneDeep");
import store from "@/store/index";
import Vue from "vue";

const initialState: JournalState = {
    meals: [],
    plans: [],
    reactions: [],
    viewing: {
        day: undefined,
        week: false,
    },
    editing: undefined,
};

const getters: GetterTree<JournalState, RootState> = {
    meals(state: JournalState): Array<Meal> | undefined {
        return state.meals;
    },
    plans(state: JournalState): Array<Plan> | undefined {
        return state.plans;
    },
    reactions(state: JournalState): Array<Reaction> | undefined {
        return state.reactions;
    },
    unreadReactions(state: JournalState): Array<Reaction> | undefined {
        return state.reactions.filter((r: Reaction) => r.read_at === null);
    },
    editing(state: JournalState): JournalEntryPayload | undefined {
        return state.editing;
    },
    viewingDay(state: JournalState): Date | undefined {
        return state.viewing.day;
    },
    viewingWeek(state: JournalState): boolean {
        return state.viewing.week;
    },
};

const mutations: MutationTree<JournalState> = {
    RESET(state: JournalState) {
        state.meals = [];
        state.viewing = {
            day: undefined,
            week: false,
        };
        state.editing = undefined;
    },
    RESET_EDITING(state: JournalState) {
        state.editing = undefined;
    },
    SET_MEALS(state: JournalState, payload: Array<Meal>) {
        state.meals = payload;
    },
    SET_PLANS(state: JournalState, payload: Array<Plan>) {
        state.plans = payload;
    },
    SET_REACTIONS(state: JournalState, payload: Array<Reaction>) {
        state.reactions = payload;
    },
    ADD_MEAL(state: JournalState, payload: Meal) {
        state.meals.push(payload);
    },
    ADD_PLAN(state: JournalState, payload: Plan) {
        state.plans.push(payload);
    },
    UPDATE_PLAN(state: JournalState, payload: Plan) {
        const index = state.plans.findIndex((model: Plan) => model.id === payload.id);

        if (index === -1) {
            state.plans.push(payload);
        } else {
            Vue.set(state.plans, index, payload);
        }
    },
    ADD_REACTION(state: JournalState, payload: Reaction) {
        state.reactions.push(payload);
    },
    UPDATE_REACTION(state: JournalState, payload: Reaction) {
        const index = state.reactions.findIndex((model: Reaction) => model.id === payload.id);

        if (index === -1) {
            state.reactions.push(payload);
        } else {
            Vue.set(state.reactions, index, payload);
        }
    },
    REMOVE_MEAL(state: JournalState, payload: Meal) {
        const index = state.meals
            .map((m) => {
                return m.id;
            })
            .indexOf(payload.id);
        if (index > -1) {
            state.meals.splice(index, 1);
        }
    },
    REMOVE_PLAN(state: JournalState, payload: Plan) {
        const index = state.plans
            .map((p) => {
                return p.id;
            })
            .indexOf(payload.id);
        if (index > -1) {
            state.plans.splice(index, 1);
        }
    },
    REMOVE_REACTION(state: JournalState, reaction_id: string | number) {
        const index = state.reactions
            .map((r) => {
                return r.id;
            })
            .indexOf(parseInt(reaction_id as string));
        if (index > -1) {
            state.reactions.splice(index, 1);
        }
    },
    SET_EDITING(state: JournalState, payload: JournalEntryPayload) {
        state.editing = payload;
    },
    SET_VIEWING_DAY(state: JournalState, date: Date) {
        state.viewing.day = date;
    },
    SET_VIEWING_WEEK(state: JournalState, isWeek: boolean) {
        state.viewing.week = isWeek;
    },
};

const actions: ActionTree<JournalState, RootState> = {
    createMeal({ commit, rootState }, payload: JournalCreatePayload) {
        return rootState.api
            .post(`accounts/${payload.account_id}/meals`, payload, { withCredentials: true })
            .then((response: { data: Meal }) => {
                commit("ADD_MEAL", response.data);

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    deleteMeal({ commit, rootState }, payload: JournalDeletePayload) {
        return rootState.api
            .delete(`accounts/${payload.account_id}/meals/${payload.meal.id}`, { withCredentials: true })
            .then((response: { data: Account }) => {
                commit("REMOVE_MEAL", payload.meal);

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    indexMeals({ commit, rootState }, payload: JournalIndexPayload) {
        const params = new URLSearchParams();

        if (payload.date_from) {
            params.append("q[and][eaten_at][]", `gte:${encodeURIComponent(format(payload.date_from, "isoDateTime"))}`);
        }

        if (payload.date_to) {
            params.append("q[and][eaten_at][]", `lt:${encodeURIComponent(format(payload.date_to, "isoDateTime"))}`);
        }

        if (payload.query) {
            params.append("q[and][or][title][]", `like:*${encodeURIComponent(payload.query.toLowerCase())}*`);
            params.append("q[and][or][title][]", `match:${encodeURIComponent(payload.query.toLowerCase())}`);
        }

        if (payload && payload.per_page) {
            params.append("per_page", payload.per_page.toString());
        } else {
            params.append("per_page", "500");
        }

        params.append("s[eaten_at]", "asc");

        const paramsString = decodeURIComponent(params.toString());

        return rootState.api
            .get(`accounts/${payload.account_id}/meals?${paramsString}`, { withCredentials: true })
            .then((response: { data: Array<Meal> }) => {
                commit("SET_MEALS", response.data);

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    updateMeal({ commit, rootState }, { payload, id }: { payload: AccountPayload; id: number }) {
        return rootState.api
            .put(`accounts/${id}`, payload, { withCredentials: true })
            .then((response: { data: Account }) => {
                commit("UPDATE_MODEL", response.data);

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    cloneSchedule({ commit, rootState }, payload: JournalCloneDayPayload) {
        return rootState.api
            .post(`accounts/${payload.account_id}/day-schedules`, payload, { withCredentials: true })
            .then((response: { data: Plan }) => {
                commit("ADD_PLAN", response.data);

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    updatePlan({ commit, rootState }, payload: JournalUpdatePlanPayload) {
        return rootState.api
            .put(`accounts/${payload.account_id}/plans/${payload.id}`, payload, { withCredentials: true })
            .then((response: { data: Plan }) => {
                commit("UPDATE_PLAN", response.data);

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    createPlan({ commit, rootState }, payload: JournalCreatePlanPayload) {
        return rootState.api
            .post(`accounts/${payload.account_id}/plans`, payload, { withCredentials: true })
            .then((response: { data: Plan }) => {
                commit("ADD_PLAN", response.data);

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    deletePlan({ commit, rootState }, payload: JournalDeletePlanPayload) {
        return rootState.api
            .delete(`accounts/${payload.account_id}/plans/${payload.plan.id}`, { withCredentials: true })
            .then((response: { data: Account }) => {
                commit("REMOVE_PLAN", payload.plan);

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    createReaction({ commit, rootState }, payload: JournalCreateReactionPayload) {
        return rootState.api
            .post(`reactions`, payload, { withCredentials: true })
            .then((response: { data: Reaction }) => {
                commit("ADD_REACTION", response.data);

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    updateReaction({ commit, rootState }, payload: JournalUpdateReactionPayload) {
        return rootState.api
            .put(`reactions/${payload.reaction_id}`, payload, { withCredentials: true })
            .then((response: { data: Reaction }) => {
                commit("UPDATE_REACTION", response.data);

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    markAsReadReaction({ commit, rootState }, payload: JournalPatchReactionPayload) {
        const readAtPayload = {
            read_at: payload.read_at,
        };
        return rootState.api
            .patch(`reactions/${payload.reaction_id}`, readAtPayload, { withCredentials: true })
            .then((response: { data: Reaction }) => {
                commit("UPDATE_REACTION", response.data);

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    deleteReaction({ commit, rootState }, payload: JournalDeleteReactionPayload) {
        return rootState.api
            .delete(`reactions/${payload.reaction_id}`, { withCredentials: true })
            .then(() => {
                commit("REMOVE_REACTION", payload.reaction_id);

                return Promise.resolve(payload.reaction_id);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    indexReactions({ commit, rootState }, payload: JournalIndexReactionsPayload) {
        const params = new URLSearchParams();

        if (payload && payload.per_page) {
            params.append("per_page", payload.per_page.toString());
        } else {
            params.append("per_page", "500");
        }

        params.append("s[created_at]", "asc");

        const paramsString = decodeURIComponent(params.toString());

        return rootState.api
            .get(`accounts/${payload.account_id}/reactions?${paramsString}`, { withCredentials: true })
            .then((response: { data: Array<Reaction> }) => {
                commit("SET_REACTIONS", response.data);

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    indexPlans({ commit, rootState }, payload: JournalIndexPlansPayload) {
        const params = new URLSearchParams();

        if (payload.date_from) {
            params.append("q[and][planned_at][]", `gte:${encodeURIComponent(format(payload.date_from, "isoDateTime"))}`);
        }

        if (payload.date_to) {
            params.append("q[and][planned_at][]", `lt:${encodeURIComponent(format(payload.date_to, "isoDateTime"))}`);
        }

        if (payload.query) {
            params.append("q[and][or][title][]", `like:*${encodeURIComponent(payload.query.toLowerCase())}*`);
            params.append("q[and][or][title][]", `match:${encodeURIComponent(payload.query.toLowerCase())}`);
        }

        if (payload && payload.per_page) {
            params.append("per_page", payload.per_page.toString());
        } else {
            params.append("per_page", "500");
        }

        params.append("s[planned_at]", "asc");

        const paramsString = decodeURIComponent(params.toString());

        return rootState.api
            .get(`accounts/${payload.account_id}/plans?${paramsString}`, { withCredentials: true })
            .then((response: { data: Array<Plan> }) => {
                // @ts-ignore
                if (!payload.hasOwnProperty("dont_save") || !payload.dont_save) {
                    commit("SET_PLANS", response.data);
                }

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    setEditing({ commit }, payload: JournalEntryPayload) {
        commit("SET_EDITING", payload);
    },
    setViewingDay({ commit }, date: Date) {
        const dateclone = cloneDeep(date);
        const fourDaysAgo = new Date();
        fourDaysAgo.setHours(0, 0, 0, 0);
        fourDaysAgo.setDate(fourDaysAgo.getDate() - 4);

        if (!store.getters["auth/isSubscriber"] && (dateclone.setHours(0, 0, 0, 0) > new Date().setHours(0, 0, 0, 0) || date < fourDaysAgo)) {
            store.dispatch("auth/setPaywall", true);
        } else {
            commit("SET_VIEWING_DAY", date);
        }
    },
    setViewingWeek({ commit }, isWeek: boolean) {
        commit("SET_VIEWING_WEEK", isWeek);
    },
    resetEditing({ commit }) {
        commit("RESET_EDITING");
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
