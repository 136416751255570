













import { Component, Vue, Prop, Emit, Watch } from "vue-property-decorator";
@Component({
    components: {
        FormInput: () => import("./input.vue"),
    },
})
export default class FormAddress extends Vue {
    @Prop() name?: string;

    @Prop() value?: Address;

    @Prop({ default: false }) disabled!: boolean;

    localValue: Address = {
        street: "",
        number: "",
        additional: "",
        city: "",
        zip: "",
        country: "",
    };

    @Emit("input")
    private handleInput() {
        return this.localValue;
    }

    @Watch("value", { immediate: true })
    private valueChanged(newValue: Address) {
        this.localValue = newValue;
    }
}
