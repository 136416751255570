import { ActionTree, MutationTree, GetterTree } from "vuex";

const initialState: DashboardState = {
    viewing: undefined,
};

const getters: GetterTree<DashboardState, RootState> = {
    viewing(state: DashboardState): Dashboard | undefined {
        return state.viewing;
    },
};

const mutations: MutationTree<DashboardState> = {
    RESET(state: DashboardState) {
        state.viewing = undefined;
    },
    SET_VIEWING(state: DashboardState, payload: Dashboard) {
        state.viewing = payload;
    },
};

const actions: ActionTree<DashboardState, RootState> = {
    getDashboard({ rootState, commit }, payload: { id: string | number; period: string }) {
        const params = new URLSearchParams();

        params.append("period", payload.period);

        const paramsString = decodeURIComponent(params.toString());

        return rootState.api
            .get(`accounts/${payload.id}/dashboard?${paramsString}`, { withCredentials: true })
            .then((response: { data: Dashboard }) => {
                commit("SET_VIEWING", response.data);
                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    reset({ commit }) {
        commit("RESET");
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
