import { ActionTree, MutationTree, GetterTree } from "vuex";
import Vue from "vue";

const initialState: InviteState = {
    all: [],
    viewing: undefined,
};

const getters: GetterTree<InviteState, RootState> = {
    all(state: InviteState): Invite[] {
        return state.all;
    },
    viewing(state: InviteState): Invite | undefined {
        return state.viewing;
    },
};

const mutations: MutationTree<InviteState> = {
    RESET(state: InviteState) {
        state.all = [];
        state.viewing = undefined;
    },
    UPDATE_MODEL(state: InviteState, payload: Invite) {
        const index = state.all.findIndex((model: Invite) => model.id === payload.id);

        if (index === -1) {
            state.all.push(payload);
        } else {
            Vue.set(state.all, index, payload);
        }
    },
    ADD_MODEL(state: InviteState, payload: Invite) {
        state.all.push(payload);
    },
    SET_MODELS(state: InviteState, payload: Invite[]) {
        state.all = payload;
    },
    SET_VIEWING(state: InviteState, payload: Invite) {
        state.viewing = payload;
    },
};

const actions: ActionTree<InviteState, RootState> = {
    create({ commit, rootState }, payload: InvitePayload) {
        return rootState.api
            .post(`invites`, payload, { withCredentials: true })
            .then((response: { data: Invite }) => {
                commit("ADD_MODEL", response.data);

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    index({ commit, rootState }) {
        return rootState.api
            .get(`invites`, { withCredentials: true })
            .then((response: { data: Invite }) => {
                commit("SET_MODELS", response.data);

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    read({ commit, rootState }, payload: { invite_code: string }) {
        return rootState.api
            .get(`invites/${payload.invite_code}`, { withCredentials: true })
            .then((response: { data: Invite }) => {
                commit("UPDATE_MODEL", response.data);

                commit("SET_VIEWING", response.data);

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    delete({ rootState }, payload: { id: number }) {
        return rootState.api
            .delete(`invites/${payload.id}`, { withCredentials: true })
            .then(() => {
                return Promise.resolve();
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
